import "./App.css";
import "./main.css";

import { useEffect, useState } from "react";

import { ethers } from "ethers"; // Import ethers.js library
import Swal from "sweetalert2";

import StakingA from "./abis/StakingA.json";
import Token from "./abis/Token.json";

// Smart Contract Address (replace with your own)
const StakingAAddress = "0x83197e9Ae083bb95f981003dC09BF268311d1D19";
const TokenAddress = "0xB00052f9B6dF3C88c56451D54799c0848E0e3778";

const App = () => {
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(true);
  const [isMetaMaskLoggedIn, setIsMetaMaskLoggedIn] = useState(true);
  const [walletAddress, setWalletAddress] = useState(StakingAAddress);
  const [walletAddressS, setWalletAddressS] = useState("");
  const [TotalStaked, setTotalStaked] = useState(0);
  const [TotalStakedAll, setTotalStakedAll] = useState(0);
  const [KendiStakelerimG, setKendiStakelerimG] = useState([]);
  const [stakeAmount, setstakeAmount] = useState(0);
  const [TXH, setTXH] = useState(1);
  const [LoadBalanceD, setLoadBalanceD] = useState(1);
  const [StakeOption, setStakeOption] = useState(0);
  const [Loading, setLoading] = useState(0);
  const [ONEbalance, setONEbalance] = useState(0);
  const [tokenMiktari, settokenMiktari] = useState(0);
  const [HowTo, setHowTo] = useState(0);
  const [Rules, setRules] = useState(0);
  const [APY, setAPY] = useState(0);

  useEffect(() => {
    const loadBalance = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const address = await signer.getAddress();
          let ONEbalance = await provider.getBalance(address);
          ONEbalance = parseInt(ONEbalance);
          ONEbalance = ONEbalance / 10 ** 6;

          let addressS = address.toString();
          addressS =
            ethers.utils.getAddress(addressS).slice(0, 6) +
            "..." +
            ethers.utils.getAddress(addressS).slice(-4);

          setWalletAddress(address);
          setWalletAddressS(addressS);
          setONEbalance(ONEbalance);
          console.log(ONEbalance);
        } catch (error) {
          console.error("Error loading balance:", error);
        }
      } else {
        console.log("Please install MetaMask to use this application.");
        setIsMetaMaskInstalled(false);
      }
    };

    loadBalance();
  }, [LoadBalanceD]);

  useEffect(() => {
    const handleContractFunction = async () => {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Create an instance of the smart contract
        const contract = new ethers.Contract(
          StakingAAddress,
          StakingA.abi,
          signer
        );

        const TokenContract = new ethers.Contract(
          TokenAddress,
          Token.abi,
          signer
        );

        let tokenMiktari = await TokenContract.balanceOf(walletAddress);
        tokenMiktari = parseInt(tokenMiktari);
        let tokenMiktariK = tokenMiktari / 10 ** 6;

        let tokenMiktariAll = await TokenContract.balanceOf(StakingAAddress);
        tokenMiktariAll = parseInt(tokenMiktariAll);
        let tokenMiktariAllK = tokenMiktariAll / 10 ** 6;

        tokenMiktariAllK = tokenMiktariAllK.toLocaleString("en-US", {
          style: "decimal",
          maximumFractionDigits: 2,
        });

        setTotalStakedAll(tokenMiktariAllK);

        tokenMiktariK = tokenMiktariK.toLocaleString("en-US", {
          style: "decimal",
          maximumFractionDigits: 2,
        });
        settokenMiktari(tokenMiktariK);

        let KisiStakeAdedi = await contract.getStakeNos(walletAddress);

        KisiStakeAdedi = parseInt(KisiStakeAdedi);

        const kendiStakelerim = [];
        let toplamstakeim = 0;

        for (var i = 1; i <= KisiStakeAdedi; i++) {
          let kendiStakelerimTekil = await contract.stakes(walletAddress, i);

          const stakinDurumu = kendiStakelerimTekil.Active.toString();

          let shortenedAddressA = kendiStakelerimTekil.Staker.toString();
          shortenedAddressA =
            ethers.utils.getAddress(shortenedAddressA).slice(0, 6) +
            "..." +
            ethers.utils.getAddress(shortenedAddressA).slice(-4);

          let StartDateX = parseInt(kendiStakelerimTekil.StartDate);
          StartDateX = StartDateX * 1000;
          let StartDateXZ = new Intl.DateTimeFormat(["ban", "id"], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(StartDateX);

          let FinishDateX = parseInt(kendiStakelerimTekil.FinishDate);
          FinishDateX = FinishDateX * 1000;
          let FinishDateXZ = new Intl.DateTimeFormat(["ban", "id"], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }).format(FinishDateX);

          const amountK = parseInt(kendiStakelerimTekil.Amount);
          let amount = amountK / 10 ** 6;
          amount = amount.toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
          });

          const prizeK = parseInt(kendiStakelerimTekil.Prize);
          let prize = prizeK / 10 ** 6;
          prize = prize.toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
          });

          if (stakinDurumu === "1") {
            kendiStakelerim.push({
              id: kendiStakelerimTekil.id.toString(),
              adres: shortenedAddressA,
              amount: amount,
              amountK: amountK,
              datestart: StartDateXZ,
              prize: prize,
              prizeK: prizeK,
              finishdate: FinishDateXZ,
              amountUnstake: 0,
            });
          }

          toplamstakeim =
            toplamstakeim + parseInt(kendiStakelerimTekil.Amount) / 10 ** 6;
        }

        setKendiStakelerimG(kendiStakelerim);
        toplamstakeim = toplamstakeim.toLocaleString("en-US", {
          style: "decimal",
          maximumFractionDigits: 2,
        });
        setTotalStaked(toplamstakeim);

        console.log("Smart contract function result:");
      } catch (error) {
        console.error("Error calling contract function:", error);
      }
    };

    handleContractFunction();
  }, [walletAddress, TXH]);

  const checkMetaMaskLogin = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        setIsMetaMaskLoggedIn(accounts.length > 0);

        window.ethereum.on("accountsChanged", (Newaccounts) => {
          // Handle wallet change
          setIsMetaMaskLoggedIn(Newaccounts.length > 0);
          setLoadBalanceD(LoadBalanceD + 1);
          console.log("cüzdan değişti");
        });
      } catch (error) {
        console.error("Error checking MetaMask login status:", error);
      }
    }
  };

  checkMetaMaskLogin();

  const updateStakeAmount = async (evt) => {
    setstakeAmount(evt.target.value);
  };

  const updateStakeOption = async (evt) => {
    if (evt.target.value === "0") {
      setStakeOption(0);
      setAPY(0);
    } else if (evt.target.value === "1") {
      setStakeOption(1);
      setAPY(40);
    } else if (evt.target.value === "2") {
      setStakeOption(2);
      setAPY(48);
    } else if (evt.target.value === "3") {
      setStakeOption(3);
      setAPY(57);
    }
  };

  const loadingOn = async () => {
    setLoading(1);
  };

  const loadingOff = async () => {
    setLoading(0);
  };

  const updateUNStakeAmount = (evt, id) => {
    const newValue = evt.target.value;

    setKendiStakelerimG((prevArray) => {
      const newArray = [...prevArray]; // Mevcut diziyi kopyala

      // amountUnstake değeri 40 olan satırın indeksini bul
      const rowIndex = newArray.findIndex((item) => item.id === id);

      if (rowIndex !== -1) {
        newArray[rowIndex].amountUnstake = newValue; // Belirli satıra yeni değeri ata
      }

      return newArray; // Yeni diziyi döndür
    });
  };

  const stakeX = async () => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Create an instance of the smart contract
      const contract = new ethers.Contract(
        StakingAAddress,
        StakingA.abi,
        signer
      );

      const TokenContract = new ethers.Contract(
        TokenAddress,
        Token.abi,
        signer
      );

      const stakeAmountK = ethers.BigNumber.from(stakeAmount).mul(
        ethers.BigNumber.from(10).pow(6)
      );

      let tokenMiktariK = await TokenContract.balanceOf(walletAddress);
      tokenMiktariK = parseInt(tokenMiktariK);

      //let status = await contract.status();
      // status = parseInt(status);

      let kullanimS = await contract.getKullanimS(walletAddress);
      kullanimS = parseInt(kullanimS);

      let simdi = await contract.simdi();
      simdi = parseInt(simdi);
      kullanimS = kullanimS + 3600; // 3600 bir saatlik saniye // 3600 yapılacak

      if (simdi < kullanimS) {
        let fark = kullanimS - simdi;
        fark = fark / 60;
        fark = parseInt(fark, 10);
        Swal.fire({
          text:
            "You need to wait for " +
            fark +
            " minutes for your next transaction",
          width: 300,
        });
      } else if (tokenMiktariK < stakeAmountK) {
        Swal.fire({
          text: "You do not have enough IVY tokens in your wallet",
          width: 300,
        });
      } else if (stakeAmount < 1) {
        Swal.fire({
          text: "Stake amount can not be less than 1",
          width: 300,
        });
      } else if (StakeOption === 0) {
        Swal.fire({
          text: "Please choose an option to continue staking",
          width: 300,
        });
      } else {
        let Verilmisizin = await TokenContract.allowance(
          walletAddress,
          StakingAAddress
        );
        Verilmisizin = parseInt(Verilmisizin);

        if (Verilmisizin === 0) {
          const transactionizin = await TokenContract.increaseAllowance(
            StakingAAddress,
            stakeAmountK,
            {
              from: walletAddress,
              gasPrice: 1010000000,
            }
          );
          loadingOn();
          await transactionizin.wait(); // Wait for the transaction to be confirmed on the blockchain
          loadingOff();

          const transaction = await contract.stake(stakeAmountK, StakeOption, {
            from: walletAddress,
            gasPrice: 1010000000,
          });
          loadingOn();
          await transaction.wait(); // Wait for the transaction to be confirmed on the blockchain
          loadingOff();
          // Transaction confirmed, execute the success handling code

          setTXH(TXH + 1);
        } else if (Verilmisizin < stakeAmountK) {
          const fark = stakeAmountK - Verilmisizin;

          const transactionizin = await TokenContract.increaseAllowance(
            StakingAAddress,
            fark,
            {
              from: walletAddress,
              gasPrice: 1010000000,
            }
          );
          loadingOn();
          await transactionizin.wait(); // Wait for the transaction to be confirmed on the blockchain
          loadingOff();

          const transaction = await contract.stake(stakeAmountK, StakeOption, {
            from: walletAddress,
            gasPrice: 1010000000,
          });
          loadingOn();
          await transaction.wait(); // Wait for the transaction to be confirmed on the blockchain
          loadingOff();
          // Transaction confirmed, execute the success handling code

          setTXH(TXH + 1);
        } else {
          const transaction = await contract.stake(stakeAmountK, StakeOption, {
            from: walletAddress,
            gasPrice: 1010000000,
          });
          loadingOn();
          await transaction.wait(); // Wait for the transaction to be confirmed on the blockchain
          loadingOff();
          // Transaction confirmed, execute the success handling code

          setTXH(TXH + 1);
        }
      }
    } catch (err) {
      // Error handling code
      console.error(err);

      setTXH(TXH + 1);
    }
  };

  const UnstakeX = async (UNid, UNamount, PrizeK, finish, amountK) => {
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Create an instance of the smart contract
      const contract = new ethers.Contract(
        StakingAAddress,
        StakingA.abi,
        signer
      );

      const TokenContract = new ethers.Contract(
        TokenAddress,
        Token.abi,
        signer
      );

      const UNamountK = ethers.BigNumber.from(UNamount).mul(
        ethers.BigNumber.from(10).pow(6)
      );

      let kullanimS = await contract.KullanimS(walletAddress);
      kullanimS = parseInt(kullanimS);

      let simdi = await contract.simdi();
      simdi = parseInt(simdi);
      kullanimS = kullanimS + 3600; // 3600 bir saatlik saniye // 3600 yapılacak

      let tokenMiktariK = await TokenContract.balanceOf(StakingAAddress);
      tokenMiktariK = parseInt(tokenMiktariK);

      let UNamountKK = 0;

      if (simdi < finish) {
        UNamountKK = UNamountK;
      } else {
        UNamountKK = PrizeK + amountK;
      }

      if (simdi < kullanimS) {
        let fark = kullanimS - simdi;
        fark = fark / 60;
        fark = parseInt(fark, 10);
        Swal.fire({
          text:
            "You need to wait for " +
            fark +
            " minutes for your next transaction",
          width: 300,
        });
      } else if (UNamountKK > tokenMiktariK) {
        Swal.fire({
          text:
            "Contract is out of balance. Code 422. Please tell this problem to the team via Telegram or Twitter" +
            UNamountKK +
            tokenMiktariK,
          width: 300,
        });
      } else {
        const transaction = await contract.unstake(UNid, UNamountK, {
          from: walletAddress,
          gasPrice: 1010000000,
        });
        loadingOn();
        await transaction.wait(); // Wait for the transaction to be confirmed on the blockchain
        loadingOff();
        // Transaction confirmed, execute the success handling code

        setTXH(TXH + 1);
      }
    } catch (err) {
      // Error handling code
      setTXH(TXH + 1);
      console.error(err);
    }
  };

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-md-5 mb-4">
          <div className="card p-5">
            <div align="center">
              <img
                src="/3D-IVY-TOKEN-1.png"
                alt="Icon"
                className="brand-logo"
              />
              <div className="mb-4">
                <div className="card mt-3">
                  <p className="card-text">
                    You need to have IVY tokens on Binance Chain(Metamask) to
                    use this Staking System
                  </p>
                  <p className="card-text">
                    Ivy Contract Address on Binance Chain:
                    0xB00052f9B6dF3C88c56451D54799c0848E0e3778
                  </p>
                </div>
              </div>
              <div className="">
                <div className="topSidebutton">
                  <button
                    className="button-64 btn btn-primary"
                    onClick={(event) => {
                      event.preventDefault();

                      if (Rules === 0) {
                        setRules(1);
                      } else {
                        setRules(0);
                      }
                    }}
                  >
                    Rules
                  </button>
                </div>
                {Rules === 1 && (
                  <div className="card mt-3">
                    <p>
                      There are 3 Staking Options in this Staking system. All
                      options have locking mechanism.
                    </p>
                    <p>
                      If you unstake your tokens before the locking period ends,
                      the system will charge you a 10% penalty and
                    </p>
                    <p>
                      you will lose the portion of your prizes corresponding to
                      the unstake amount
                    </p>
                    <p>
                      If you unstake your tokens after the locking period ends,
                      You will receive your tokens and rewards together without
                      any penalty.
                    </p>
                    <p>Option 1: (Locking for 6 months, APY is 40%)</p>
                    <p>Option 2: (Locking for 12 months, APY is 48%)</p>
                    <p>Option 3: (Locking for 24 months, APY is 57%)</p>
                    <p>APY = Annual Percentage Yield ( The rate for 1 year )</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-7">
          <div className="card p-5">
            {Loading === 1 && (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}

            <h1>IVY Staking</h1>

            {isMetaMaskInstalled ? (
              <>
                {isMetaMaskLoggedIn ? (
                  <>
                    <div className="top">
                      <div class="alert alert-info">
                        <b>Your Address: </b>
                        {walletAddressS}{" "}
                      </div>
                      <div class="alert alert-info">
                        <b>Your IVY tokens: </b>
                        {tokenMiktari}{" "}
                      </div>
                      <div class="alert alert-info">
                        <b>Your Total Staked: </b>
                        {TotalStaked}{" "}
                      </div>
                      <div class="alert alert-info">
                        <b>Total Staked IVY tokens: </b>
                        {TotalStakedAll}{" "}
                      </div>
                    </div>
                    <div className="putStake mb-4">
                      {" "}
                      <h4 className="h4">APY {APY}%</h4>
                      <div className="form-group">
                        <select
                          className="form-control mb-1"
                          name="options"
                          id="options"
                          onChange={updateStakeOption}
                        >
                          <option value="0">Option</option>
                          <option value="1">6 Months</option>
                          <option value="2">12 Months</option>
                          <option value="3">24 Months</option>
                        </select>
                        <input
                          className="form-control mb-2"
                          id="stakeInput"
                          type="number"
                          value={stakeAmount}
                          onChange={updateStakeAmount}
                        />

                        <button
                          className="button-64 btn btn-success"
                          onClick={(event) => {
                            event.preventDefault();
                            stakeX();
                          }}
                        >
                          Stake
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead className="tableTop">
                          <tr>
                            <th className="td" id="amount">
                              Amount
                            </th>
                            <th className="td" id="unstake">
                              Unstake
                            </th>
                            <th className="td" id="startDate">
                              Start
                            </th>
                            <th className="td" id="cPrize">
                              Prize
                            </th>

                            <th className="td" id="LPR">
                              Finish
                            </th>
                          </tr>
                        </thead>
                        <tbody className="stacksDiv">
                          {KendiStakelerimG.map((A) => {
                            return (
                              <tr key={A.id} className="stacks">
                                <td className="td">{A.amount}</td>
                                <td className="td">
                                  <input
                                    className="form-control mb-2"
                                    type="number"
                                    id={A.id}
                                    value={A.amountUnstake}
                                    onChange={(event) =>
                                      updateUNStakeAmount(event, A.id)
                                    }
                                  />

                                  <button
                                    className="button-64 btn btn-danger"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      UnstakeX(
                                        A.id,
                                        A.amountUnstake,
                                        A.prizeK,
                                        A.finishdate,
                                        A.amountK
                                      );
                                    }}
                                  >
                                    Unstake
                                  </button>
                                </td>
                                <td className="td" id="startDateB">
                                  {A.datestart}
                                </td>
                                <td className="td" id="cPrizeB">
                                  {A.prize}
                                </td>
                                <td className="td" id="LPRB">
                                  {A.finishdate}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <p>Please log in to your MetaMask wallet.</p>
                )}
              </>
            ) : (
              <p>Please install MetaMask to use this application.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
